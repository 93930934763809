var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { Box, Divider, Typography, Grid, IconButton } from '@mui/material';
import './CandidateInfo.scss';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { Link, useNavigate } from 'react-router-dom';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import TPToast from '../../../../tandym-web-common/src/shared/components/toast/TPToast';
import downloadDisabledImg from '../../../../tandym-web-common/src/images/download-disabled.png';
import { IMaskInput } from 'react-imask';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { getInitials } from '../../../../tandym-web-common/src/shared/utilities/Candidate';
import { useDownloadResume, useFileUpload } from '../../utilities/API';
var iconStyle = { pl: '0.125rem !important' };
var boxStyle = {
    display: 'flex',
    alignItems: 'center',
    mt: '1.125rem',
    textAlign: 'left',
};
var InfoRow = function (props) {
    var icon = props.icon, title = props.title, subtitle = props.subtitle, link = props.link;
    return (_jsxs(Grid, __assign({ sx: boxStyle, container: true, spacing: 2, id: title + 'info', "data-testid": title + 'info' }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: { display: 'flex', justifyContent: 'center' } }, { children: icon === 'email-icon' ? (_jsx(TPSvgIcon, { resourceName: 'email-icon' })) : icon === 'call-icon' ? (_jsx(TPSvgIcon, { resourceName: 'call-icon' })) : icon === 'address-icon' ? (_jsx(TPSvgIcon, { resourceName: 'address-icon' })) : (_jsx(TPSvgIcon, { resourceName: 'website-icon' })) })), _jsxs(Grid, __assign({ item: true, xs: 10 }, { children: [_jsx(Typography, __assign({ id: title + 'info', "data-testid": title + 'info', className: "".concat(title.toLowerCase().replace(' ', '-')), fontSize: fontSizeDynamic(16) }, { children: title })), title === 'Mobile Phone' ? (_jsx(IMaskInput, { id: 'mobilePhone', "data-testid": 'mobilePhone', className: 'subtitle', value: subtitle, mask: '(000) 000-0000', readOnly: true, disabled: true, style: {
                            border: 'none',
                            outline: 'none',
                            background: 'transparent',
                            fontSize: fontSizeDynamic(14),
                        } })) : link ? (_jsx(Link, __assign({ to: link, target: '_blank', rel: 'noopener noreferrer', className: 'subtitle linkedIn-link', style: { fontSize: fontSizeDynamic(14) } }, { children: subtitle }))) : (_jsx(Typography, __assign({ className: 'subtitle', fontSize: fontSizeDynamic(14), id: title, "data-testid": title }, { children: subtitle })))] }))] })));
};
export var CandidateInfoContactDetails = function (profileData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return (_jsxs(_Fragment, { children: [' ', _jsxs(Box, __assign({ sx: { px: '1.5rem' } }, { children: [_jsx(Typography, __assign({ className: 'candidate-info-contact-details', id: 'contact-details', "data-testid": 'contact-details', fontSize: fontSizeDynamic(16), sx: { textAlign: 'left' } }, { children: "Contact Details" })), _jsx(InfoRow, { icon: 'call-icon', title: 'Mobile Phone', subtitle: (_b = (_a = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _a === void 0 ? void 0 : _a.mobilePhone) !== null && _b !== void 0 ? _b : '' }), _jsx(InfoRow, { icon: 'email-icon', title: 'Email Address', subtitle: (_d = (_c = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _c === void 0 ? void 0 : _c.personalEmail) !== null && _d !== void 0 ? _d : '' }), _jsx(InfoRow, { icon: 'address-icon', title: 'Address', subtitle: profileData
                            ? [
                                (_e = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _e === void 0 ? void 0 : _e.addressLine1,
                                (_f = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _f === void 0 ? void 0 : _f.city,
                                (_g = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _g === void 0 ? void 0 : _g.state,
                                (_h = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _h === void 0 ? void 0 : _h.country,
                            ]
                                .filter(Boolean)
                                .join(', ')
                            : '' }), _jsx(InfoRow, { icon: 'website-icon', title: 'LinkedIn', subtitle: (_k = (_j = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _j === void 0 ? void 0 : _j.linkedIn) !== null && _k !== void 0 ? _k : '', link: (_m = (_l = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _l === void 0 ? void 0 : _l.linkedIn) !== null && _m !== void 0 ? _m : '' })] })), _jsx(Divider, { sx: { my: '1.813rem' } }), _jsxs(Box, __assign({ sx: { px: '1rem', pb: '2.25rem' } }, { children: [_jsx(Typography, __assign({ className: 'summary-text', id: 'summary-text', "data-testid": 'summary-text', fontSize: fontSizeDynamic(16), sx: { textAlign: 'left' } }, { children: "About" })), _jsx(Typography, __assign({ className: 'summary', fontSize: fontSizeDynamic(14), id: 'summary', "data-testid": 'summary' }, { children: profileData === null || profileData === void 0 ? void 0 : profileData.profileSummary }))] }))] }));
};
export var CandidateResume = function (profileData, uploadResume, downloadResume) {
    var widthAndHeightForImages = window.innerWidth / 60;
    return (_jsx(Box, __assign({ sx: { display: 'flex', alignItems: 'center', mt: '1.688rem' } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { alignItems: 'center', ml: '0rem !important' } }, { children: [_jsx(Grid, { item: true, xs: 2, sm: 1, sx: iconStyle }), _jsx(Grid, __assign({ item: true, xs: 6, sm: 8, sx: iconStyle }, { children: _jsx(Typography, __assign({ title: 'Romel_Chakma_Resume_2024.PDF', className: 'candidate-info-resume', id: 'candidate-info-resume', "data-testid": 'candidate-info-resume', fontSize: fontSizeDynamic(14) }, { children: (profileData === null || profileData === void 0 ? void 0 : profileData.isResumeAvailable)
                            ? 'cv.docx'
                            : 'Resume not available' })) })), _jsx(Grid, __assign({ item: true, xs: 2, sm: 2, sx: { display: 'flex', justifyContent: 'center' }, onClick: uploadResume }, { children: _jsx(TPSvgIcon, { resourceName: 'upload-icon' }) })), (profileData === null || profileData === void 0 ? void 0 : profileData.isResumeAvailable) ? (_jsx(Grid, __assign({ item: true, xs: 2, sm: 1, sx: { display: 'flex', justifyContent: 'center' }, onClick: downloadResume }, { children: _jsx(TPSvgIcon, { resourceName: 'download-icon' }) }))) : (_jsx(Grid, __assign({ item: true, xs: 2, sm: 1, sx: { display: 'flex', justifyContent: 'center' } }, { children: _jsx("img", { src: downloadDisabledImg, alt: 'download-img', width: widthAndHeightForImages, height: widthAndHeightForImages }) })))] })) })));
};
var CandidateInfo = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var profileData = props.profileData, showUploadResume = props.showUploadResume;
    var navigate = useNavigate();
    var fileInputRef = useRef(null);
    var _l = useFileUpload(), fileChange = _l.fileChange, errorMessage = _l.errorMessage, isLoading = _l.isLoading, successMessage = _l.successMessage;
    var downloadResume = useDownloadResume().downloadResume;
    var fullName = profileData
        ? "".concat((_b = (_a = profileData.generalDetails) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '', " ").concat((_d = (_c = profileData.generalDetails) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '')
        : '';
    var location = profileData
        ? [
            (_e = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _e === void 0 ? void 0 : _e.city,
            (_f = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _f === void 0 ? void 0 : _f.state,
            (_g = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _g === void 0 ? void 0 : _g.country,
        ]
            .filter(Boolean)
            .join(', ')
        : '';
    var getFontSize = function () { return (window.innerWidth > 1536 ? 24 : 19.2); };
    var uploadResume = function () {
        if (fileInputRef.current) {
            if (successMessage) {
                return;
            }
            fileInputRef.current.click();
        }
    };
    var handleFileChange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            fileChange(event);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(Box, __assign({ id: 'candidate-info', "data-testid": 'candidate-info', className: 'candidate-info' }, { children: [_jsx(TPToast, { message: errorMessage, open: errorMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'error' }), _jsx(Box, __assign({ className: 'candidate-info-first' }, { children: _jsx(IconButton, __assign({ className: 'edit-icon', sx: { position: 'absolute', top: 0 }, onClick: function () { return navigate('/profile/personal-details'); } }, { children: _jsx(TPSvgIcon, { resourceName: 'edit-icon' }) })) })), _jsx(TPSvgIcon, { id: 'avatar-icon', "data-testid": 'avatar-icon', resourceName: 'avatar-icon', "font-size": getFontSize(), className: 'avatar-icon', text: getInitials(fullName) }), _jsxs(Box, __assign({ sx: { px: '1.5rem' } }, { children: [_jsxs(Typography, __assign({ className: 'candidate-info-name', id: 'candidate-info-name', "data-testid": 'candidate-info-name', fontSize: fontSizeDynamic(20) }, { children: [(_h = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _h === void 0 ? void 0 : _h.firstName, ' ', (_j = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _j === void 0 ? void 0 : _j.lastName] })), _jsx(Typography, __assign({ className: 'candidate-info-course', id: 'candidate-info-course', "data-testid": 'candidate-info-course', fontSize: fontSizeDynamic(14) }, { children: (_k = profileData === null || profileData === void 0 ? void 0 : profileData.education[0]) === null || _k === void 0 ? void 0 : _k.course })), _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            gap: '0.313rem',
                            alignItems: 'center',
                            mt: '0.563rem',
                        } }, { children: [_jsx(IconButton, { children: _jsx(TPSvgIcon, { resourceName: 'location-icon' }) }), _jsx(Typography, __assign({ className: 'candidate-info-location', id: 'candidate-info-location', "data-testid": 'candidate-info-location', fontSize: fontSizeDynamic(14) }, { children: location }))] })), showUploadResume &&
                        CandidateResume(profileData !== null && profileData !== void 0 ? profileData : null, uploadResume, downloadResume), successMessage && (_jsx(Typography, __assign({ className: 'candidate-info-success-message', fontSize: fontSizeDynamic(14) }, { children: "Your resume has been uploaded successfully and will be updated within an hour" })))] })), _jsx(Divider, { sx: { my: '1.813rem' } }), CandidateInfoContactDetails(profileData !== null && profileData !== void 0 ? profileData : null), _jsx("input", { type: 'file', ref: fileInputRef, className: 'upload-resume', onChange: handleFileChange }), isLoading && _jsx(TPProgressModal, { isLoading: isLoading })] })));
};
export default CandidateInfo;
