export var proficiencyOpt = [{
        label: 'Basic', value: '143570000'
    },
    {
        label: 'Working', value: '143570001'
    },
    {
        label: 'Good', value: '143570002'
    },
    {
        label: 'Excellent', value: '143570003'
    },
];
export var experiencePeriodOpt = [{
        label: 'Month', value: '143570000'
    },
    {
        label: 'Year', value: '143570001'
    }
];
