export var FrequencyMock = [
    {
        id: 1,
        label: 'Hour',
    },
    {
        id: 2,
        label: 'Day',
    },
    {
        id: 3,
        label: 'Week',
    },
    {
        id: 4,
        label: 'Month',
    },
    {
        id: 5,
        label: 'Year',
    },
];
export var EmployementTypeMock = [
    {
        id: 1,
        label: 'Contractual ',
    },
    {
        id: 2,
        label: 'Temporary',
    },
    {
        id: 3,
        label: 'Permanent',
    },
];
export var RoleMock = [
    {
        id: 1,
        label: 'Registered Nurse',
    },
    {
        id: 2,
        label: 'Nurse Practitioner',
    },
    {
        id: 3,
        label: 'Clinical Nurse Specialist',
    },
    {
        id: 4,
        label: 'Certified Nurse Midwife',
    },
    {
        id: 5,
        label: 'Certified Registered Nurse Anesthetist',
    },
    {
        id: 6,
        label: 'Nurse Educator',
    },
    {
        id: 7,
        label: 'Nurse Manager',
    },
    {
        id: 8,
        label: 'infection Control Nurse',
    },
];
export var ContactTypes = [
    { id: 1, label: 'New Job Information' },
    { id: 2, label: 'Call me' },
    { id: 3, label: 'Send a text message' },
    { id: 4, label: 'Receive E-mail' },
];
export var ContactPreferenceMock = {
    newJobInformation: true,
    callMe: true,
    sendatextmessage: true,
    receiveEmail: true,
};
