export var sickTimeFaqs = [
    {
        id: 1,
        question: "If I switch to Tandym, what happens to the sick hours I've accrued with my previous employer?",
        answer: 'As per state guidelines, accrued sick hours from previous employment do not carry over when you switch to Tandym. However, Tandym has provisions to front-load sick hours if needed, which means they can provide you with sick leave hours in advance, and these will be adjusted as you accrue more hours with Tandym.',
    },
];
export var timesheetFaqs = [
    {
        id: 1,
        question: 'How do I handle issues with email timesheet approvals? ',
        answer: 'If you encounter an error while trying to approve timesheets through an email link, it is recommended to log directly into the Tandym portal as an alternative. If issues persist, capture a screenshot of the error and send it to fieldstaffconcierge@tandymgroup.com for further assistance',
    },
    {
        id: 2,
        question: 'What should I do if I receive an error notification during timesheet approval?',
        answer: 'Notify fieldstaffconcierge@tandymgroup.com of the error, as indicated by the error message. Provide details about the issue, including any screenshots, to help diagnose and resolve the problem.',
    },
    {
        id: 3,
        question: "Can I approve a timesheet if I've already tried through an email link but faced an issue?",
        answer: 'Yes, you can log in to the Tandym portal and approve the timesheet directly. This method is generally more reliable if you encounter problems with email-based approval.',
    },
    {
        id: 4,
        question: 'Is there a risk of double approval when approving timesheets through different methods?',
        answer: 'No, the system is designed to prevent double approval, so you can safely attempt approval through another method if the first one fails.',
    },
    {
        id: 5,
        question: 'What steps should I take if I need to approve from a mobile device and encounter issues?',
        answer: "Although mobile approvals are supported, issues might occur. It's best to switch to a desktop view if possible or wait until you can access a desktop if mobile errors persist.",
    },
    {
        id: 6,
        question: 'How can I ensure a smooth timesheet approval process in the future?',
        answer: "Regularly check for updates from Tandym on system improvements and use the direct portal login for approvals to avoid potential email link issues. Also, attending Tandym's office hours can provide updates and direct support for any ongoing concerns.",
    },
];
export var healthBenefitsFaqs = [
    {
        id: 1,
        question: 'When am I eligible for benefits?',
        answer: '',
        bullets: [
            'All full-time field employees, working 30 or more hours per week, are eligible for benefits. Employees become effective under the plans on the first of the month following 60 days of employment. This applies to Medical, Dental, Vision, HSA, Flexible Spending Account, Life Insurance, and Supplemental insurance.',
            "Retirement 401(k) is available to employee's day 1 of employment.",
        ],
    },
    {
        id: 2,
        question: 'Where do I make my election for benefits?',
        answer: 'Elections for benefits are to be done in our People cloud portal iSolved.',
        bullets: [
            'Desktop:https://emplyedrive.myisolved.com',
            'Mobile:https://employdrive.myisolved.com/cloudservice',
        ],
    },
    {
        id: 3,
        question: 'What benefits am I offered through Tandym?',
        answer: '',
        bullets: [
            'Medical (Meritain/Aetna)',
            'Medical-Minimum Essential Coverage (WellCare)',
            'Dental (Delta Dental)',
            'Vision (VSP)',
            '401(k)-Fidelity',
            'Flexible Spending Account/HSA (Seneca TPA)',
            'Life Insurance (Lincoln Financial)',
            'RX Discount (RX Help)',
            'Pet Insurance discount (Nationwide)',
            'Discount Program (PerkSpot)',
        ],
    },
    {
        id: 4,
        question: "How do I handle the transition from my current insurance to Tandym's insurance?",
        answer: '',
        bullets: [
            'If you are choosing to enroll on Tandym’s benefits, then you will need to let your current carrier know that insurance coverage will begin the same date of your date of hire.',
            'If you choose to stay with your current plan you may join with our plan only if your current coverage/carrier terminates coverage. That will then be considered qualifying event.',
        ],
    },
    {
        id: 5,
        question: 'What happens if I do not enroll on Tandym benefits?',
        answer: 'Employees have 30 days from the day that they are eligible to enroll on Tandym benefits. If you miss the enrollment period, you are only allowed to enroll on Tandym’s benefits if you have a qualifying event or if there is not qualifying event in place then you will have to wait for January for the following year for our annual Open enrollment.',
    },
    {
        id: 6,
        question: 'What is a qualifying event?',
        answer: 'A change in your situation — like getting married, having a baby, or losing health coverage — that can make you eligible for a Special Enrollment Period, allowing you to enroll in health insurance outside the yearly Open Enrollment Period. ',
        subAnswer: 'There are 4 basic types of qualifying life events.',
        orderList: [
            {
                id: 1,
                text: '	Loss of health coverage',
                subList: [
                    {
                        id: 1,
                        text: 'Losing existing health coverage, including job-based, individual, and student plans',
                    },
                    {
                        id: 2,
                        text: 'Losing eligibility for Medicare, Medicaid',
                    },
                    {
                        id: 3,
                        text: "Turning 26 and losing coverage through a parent's plan",
                    },
                ],
            },
            {
                id: 2,
                text: 'Changes in household',
                subList: [
                    {
                        id: 1,
                        text: 'Getting married or divorced',
                    },
                    {
                        id: 2,
                        text: 'Having a baby or adopting a child',
                    },
                    {
                        id: 3,
                        text: 'Death in the family',
                    },
                ],
            },
            {
                id: 3,
                text: 'Changes in residence',
                subList: [
                    {
                        id: 1,
                        text: 'Moving to a different ZIP code or county',
                    },
                    {
                        id: 2,
                        text: 'A student moving to or from the place they attend school',
                    },
                    {
                        id: 3,
                        text: 'A seasonal worker moving to or from the place they both live and work',
                    },
                    {
                        id: 4,
                        text: 'Moving to or from a shelter or other transitional housing',
                    },
                ],
            },
            {
                id: 4,
                text: 'Other qualifying events',
                subList: [
                    {
                        id: 1,
                        text: 'Changes in your income that affect the coverage you qualify for',
                    },
                    {
                        id: 2,
                        text: 'Becoming a U.S. citizen',
                    },
                ],
            },
        ],
    },
    {
        id: 7,
        question: 'What should I do if I have paid for insurance coverage beyond my start date with Tandym?',
        answer: "You can coordinate the start of Tandym's insurance from the time your current insurance coverage ends. This situation also qualifies as a qualifying event, allowing you to switch without penalty or gap.",
    },
    {
        id: 8,
        question: 'What happens if I am covered under Cobra and have paid for (x) amount of months?',
        answer: "You are then able to enroll in Tandym's benefits once your cobra coverage ends. That is then considered a qualifying event.",
    },
    {
        id: 9,
        question: 'How soon will it take for me to get my insurance cards once I make my elections?',
        answer: 'Medical cards can take 5-10 business days to be mailed. You make get your card prior to you having your insurance active.',
        bullets: [
            'Dental and vision - You will not receive physical cards for these plans but instructions to access digital one online with carrier portal',
            'Flexible spending and HSA - are mailed to you within 5-10 business days from enrollment.',
        ],
    },
    {
        id: 10,
        question: 'Who should I contact for questions about enrolling in benefits or changing my insurance details?',
        answer: '',
        bullets: [
            'For any queries related to benefits or insurance adjustments, you can email benefits@tandymgroup.com, and either the assigned contact person or their colleague will assist you.',
        ],
    },
];
