var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { fetchNajaxaToken } from '../../../../tandym-web-common/src/shared/utilities/FetchNajaxatoken';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Pay = function () {
    var _a = useState(true), renderComponent = _a[0], setRenderComponent = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState(''), token = _d[0], setToken = _d[1];
    var httpPost = useAPI().httpPost;
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _e = useAppSelector(function (state) { return state.userAuth; }), email = _e.email, paycheckURL = _e.paycheckURL;
    var najaxaURL = TANDYM_CONSTANTS.API_URLS.NAJAXA_API;
    useEffect(function () {
        setRenderComponent(entitlementsData.entitlementDto.pay);
    }, [entitlementsData]);
    useEffect(function () {
        fetchNajaxaToken(httpPost, najaxaURL, email, setIsLoading, setToken, setError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [httpPost, najaxaURL, email]);
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    return (_jsx(Box, __assign({ sx: { textAlign: 'center' }, id: 'pay', "data-testid": 'pay' }, { children: isLoading ? (_jsx(TPProgressModal, { isLoading: isLoading })) : error ? (_jsx(Typography, __assign({ color: 'error', fontSize: fontSizeDynamic(16) }, { children: error }))) : (_jsx("div", __assign({ className: 'najaxa-iframe' }, { children: _jsx("iframe", { id: 'najaxa-iframe', src: "".concat(paycheckURL, "?jwt=").concat(token), title: 'Najaxa', allowFullScreen: true, className: 'najaxa-iframe-content' }) }))) })));
};
export default Pay;
