var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TPCustomModal from '../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import { Box, Grid, Typography } from '@mui/material';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import './navigationGuard.scss';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var NavigationGuard = function (_a) {
    var _b;
    var open = _a.open, onClose = _a.onClose, title = _a.title, description = _a.description, cancelButton = _a.cancelButton, cancelButtonText = _a.cancelButtonText, cancel = _a.cancel, cancelDisable = _a.cancelDisable, saveButton = _a.saveButton, saveButtonText = _a.saveButtonText, saveAndProceed = _a.saveAndProceed, saveAndProceedDisable = _a.saveAndProceedDisable, buttonContainerCSS = _a.buttonContainerCSS, _c = _a.TPSVGIcon, TPSVGIcon = _c === void 0 ? false : _c, TPSvgIconResourceName = _a.TPSvgIconResourceName, errorsText = _a.errorsText, errorMessage = _a.errorMessage;
    var popupId = 'navigationGuard-popup';
    var buttonSx = { display: 'flex', margin: 'auto', gap: 2, mt: 5 };
    return (_jsx(TPCustomModal, { open: open, onClose: onClose, PaperProps: { sx: { width: '513px' } }, children: _jsxs(Grid, __assign({ container: true, flexDirection: 'column', m: 'auto' }, { children: [_jsxs(Grid, __assign({ item: true }, { children: [!TPSVGIcon ? (_jsx(Box, __assign({ sx: {
                                display: 'flex',
                                justifyContent: 'center',
                            } }, { children: _jsx(TPSvgIcon, { id: "".concat(popupId, "-icon"), resourceName: TPSvgIconResourceName
                                    ? TPSvgIconResourceName
                                    : 'navigation-guard-icon' }) }))) : null, _jsx(Typography, __assign({ id: "".concat(popupId, "--title"), "data-testid": "".concat(popupId, "--title"), className: 'navigationGuard-title', sx: {
                                mt: '21px',
                                textAlign: 'center',
                                fontSize: fontSizeDynamic(24),
                            } }, { children: title ? title : 'Unsaved Changes' }))] })), _jsx(Typography, __assign({ className: 'bodyText', mt: 1, id: "".concat(popupId, "-description"), "data-testid": "".concat(popupId, "-description"), fontSize: fontSizeDynamic(14) }, { children: description
                        ? description
                        : 'You have unsaved changes. Would you like to save before proceeding? To disregard the changes or to modify further and save, click cancel.' })), _jsxs(Box, __assign({ sx: buttonContainerCSS ? buttonContainerCSS : buttonSx }, { children: [cancelButton && (_jsx(TPButton, { id: "".concat(popupId, "-cancel-button"), "data-testid": "".concat(popupId, "-cancel-button"), children: cancelButtonText ? cancelButtonText : 'Cancel', color: 'secondary', onClick: cancel, disabled: cancelDisable, sx: { fontSize: fontSizeDynamic(14) } })), saveButton && (_jsx(TPButton, { id: "".concat(popupId, "-Save-and-proceed-button"), "data-testid": "".concat(popupId, "-Save-and-proceed-button"), children: saveButtonText ? saveButtonText : 'Save and Proceed', color: 'primary', onClick: saveAndProceed, disabled: saveAndProceedDisable, sx: { fontSize: fontSizeDynamic(14) } }))] })), (errorMessage || errorsText) && (_jsx(Box, __assign({ textAlign: 'center', mt: 2 }, { children: _jsxs(Typography, __assign({ className: 'error_message', id: 'zip-code-error', "data-testid": 'zip-code-error' }, { children: [errorMessage && errorMessage, errorsText &&
                                ((_b = Object.keys(errorsText)) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                                'Please fill in all required fields'] })) })))] })) }));
};
export default NavigationGuard;
