var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
var StyledSwitch = styled(function (props) { return (_jsx(Switch, __assign({}, props))); })(function (_a) {
    var theme = _a.theme, checked = _a.checked;
    return ({
        width: 44,
        height: 24,
        padding: 0,
        border: "1px solid ".concat(checked ? '#2A70EA' : '#E31A1C'),
        borderRadius: '26px',
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 0,
            top: '-1px',
            left: checked ? '2px' : '0px',
            transitionDuration: '300ms',
            color: checked ? '#2A70EA' : '#E31A1C',
            '&.Mui-checked': {
                transform: 'translateX(17px)',
                color: '#2A70EA',
                '& + .MuiSwitch-track': {
                    backgroundColor: checked ? ' #E0EBFF' : '#FFE0E0',
                    opacity: 1,
                    border: 0,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#2A70EA',
                border: '6px solid #fff',
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#FFE0E0' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    });
});
function CustomizedSwitch(props) {
    var open = props.open, onClick = props.onClick, label = props.label, rest = __rest(props, ["open", "onClick", "label"]);
    return (_jsx(FormGroup, { children: _jsx(FormControlLabel, __assign({}, rest, { id: 'contact-preference-switch', "data-testid": 'contact-preference-switch', sx: { mx: '0.813rem' }, control: _jsx(StyledSwitch, { inputProps: {
                    'data-testid': label + '-preference-switch',
                }, id: label + '-preference-switch', checked: open, onChange: function () { return onClick(!open); } }), label: '' })) }));
}
export default CustomizedSwitch;
