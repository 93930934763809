var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { placementComplianceColumns } from '../../../__mocks__/Compliance.mock';
import './Compliance.scss';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import TPCustomModal from '../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import Evidence from './Evidence';
import { usePlacementApiContext } from '../placements';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import downloadImg from '../../../../../tandym-web-common/src/images/download.png';
import downloadDisabledImg from '../../../../../tandym-web-common/src/images/download-disabled.png';
import TPToast from '../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import NoPageFound from '../../../../../tandym-web-common/src/components/NoPageFound';
import { useAppSelector } from '../../../store/hook';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
dayjs.extend(utc);
var setColors = function (status, color) {
    switch (status) {
        case 'Rejected':
            return color.red;
        case 'Submitted':
            return color.green;
        case 'Outstanding':
            return color.grayBlue;
        default:
            return color.gray;
    }
};
var Compliance = function () {
    var _a;
    var _b = useState([]), complianceData = _b[0], setComplianceData = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), statusLoading = _d[0], setStatusLoading = _d[1];
    var _e = useState(''), successMessage = _e[0], setSuccessMessage = _e[1];
    var _f = useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var placementId = usePlacementApiContext().placementId;
    var _g = useAPI(), httpGet = _g.httpGet, httpPost = _g.httpPost;
    var _h = useState({
        name: '',
        validFrom: '',
        validTo: '',
        status: '',
        id: '',
    }), evidenceObj = _h[0], setEvidenceObj = _h[1];
    var _j = useState({}), sortOrder = _j[0], setSortOrder = _j[1];
    var _k = useState(true), renderComponent = _k[0], setRenderComponent = _k[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        var _a;
        setRenderComponent((_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.placement);
    }, [entitlementsData]);
    var sortHandler = function (columnId) {
        var _a;
        var order = sortOrder[columnId] === 'asc' ? 'desc' : 'asc';
        setSortOrder((_a = {}, _a[columnId] = order, _a));
        var sortedData = __spreadArray([], complianceData, true).sort(function (a, b) {
            var aValue = a[columnId];
            var bValue = b[columnId];
            if (['validFrom', 'validTo', 'lastUpdatedOn'].includes(columnId)) {
                aValue = dayjs(aValue).unix();
                bValue = dayjs(bValue).unix();
            }
            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            else if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
            else {
                return 0;
            }
        });
        setComplianceData(sortedData);
    };
    var submitHandler = function (evidence, validFrom, validTo, status, id) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "".concat(status === 'Rejected' ? 'Re-submit' : 'Submit', " Compliance Evidence Button"),
        });
        setIsLoading(true);
        setEvidenceObj(function (prev) { return (__assign(__assign({}, prev), { name: evidence, validFrom: validFrom, validTo: validTo, status: status, id: id })); });
    };
    var fetchCompliance = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setStatusLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_PLACEMENT_BYID_GET, { placementsId: placementId })];
                case 1:
                    response = _a.sent();
                    if (response.error === false) {
                        setComplianceData(response.response);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setStatusLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (placementId) {
            fetchCompliance();
        }
    }, [placementId]);
    var downloadEvidence = function (id, filePath) { return __awaiter(void 0, void 0, void 0, function () {
        var response, fileContents, fileDownloadName, byteCharacters, byteNumbers, i, byteArray, blob, url, a, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setStatusLoading(true);
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_DOWNLOAD_EVIDENCE_POST, {
                            ComplianceId: id,
                            PlacementId: placementId,
                            FilePath: filePath,
                        })];
                case 1:
                    response = _a.sent();
                    if (response.error) {
                        setErrorMessage(response.message);
                        return [2 /*return*/];
                    }
                    else {
                        fileContents = response.fileContents, fileDownloadName = response.fileDownloadName;
                        // Check if fileContents is a Base64 string
                        if (typeof fileContents === 'string') {
                            byteCharacters = atob(fileContents);
                            byteNumbers = new Array(byteCharacters.length);
                            for (i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            byteArray = new Uint8Array(byteNumbers);
                            blob = new Blob([byteArray], { type: response.contentType });
                            url = window.URL.createObjectURL(blob);
                            a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = fileDownloadName.endsWith('.pdf')
                                ? fileDownloadName
                                : "".concat(fileDownloadName, ".pdf");
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        else {
                            console.error('Unexpected fileContents format:', fileContents);
                            setErrorMessage('Invalid file format received');
                        }
                    }
                    return [3 /*break*/, 4];
                case 2:
                    err_1 = _a.sent();
                    console.error('Download error:', err_1);
                    setErrorMessage('Error while downloading evidence');
                    return [3 /*break*/, 4];
                case 3:
                    setStatusLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (successMessage || errorMessage) {
            var timer_1 = setTimeout(function () {
                setSuccessMessage('');
                setErrorMessage('');
            }, 3000);
            return function () { return clearTimeout(timer_1); };
        }
    }, [successMessage, errorMessage]);
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    var isHideDownload = (_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.showDownload;
    return (_jsxs(Box, __assign({ className: 'compliance', id: 'compliance', "data-testid": 'compliance' }, { children: [_jsx(TPToast, { message: successMessage, open: successMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'success' }), _jsx(TPToast, { message: errorMessage, open: errorMessage !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'error' }), _jsx(TableContainer, __assign({ component: Paper, sx: {
                    '&.MuiTableContainer-root': {
                        boxShadow: 'none',
                    },
                } }, { children: _jsxs(Table, __assign({ sx: { minWidth: 650 }, "aria-label": 'table' }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: placementComplianceColumns.map(function (item, index) {
                                    if (!isHideDownload && item.label === 'EVIDENCE') {
                                        return null;
                                    }
                                    return (_jsx(TableCell, __assign({ className: 'compliance-table-row-header', id: "compliance-table-row-header-".concat(item.id), "data-testid": "compliance-table-row-header-".concat(item.id), sx: __assign(__assign({}, (index === 0 && { borderRight: '1px solid #EAECF0' })), (index === placementComplianceColumns.length - 1 && {
                                            borderLeft: '1px solid #EAECF0',
                                        })) }, { children: _jsxs(Box, __assign({ className: 'compliance-table-header-section' }, { children: [_jsx(Typography, __assign({ className: 'compliance-header-text', id: "compliance-header-text-".concat(item.id), "data-testid": "compliance-header-text-".concat(item.id), fontSize: fontSizeDynamic(14) }, { children: item.label })), _jsx("div", { children: item.id !== 'evidence' && item.id !== 'action' ? (_jsx(TPSvgIcon, { resourceName: 'sort-icon', className: 'compliance-sort-icon', id: "compliance-sort-icon-".concat(item.id), "data-testid": "compliance-sort-icon-".concat(item.id), onClick: function () { return sortHandler(item.id); } })) : null })] })) }), item.id));
                                }) }) }), _jsx(TableBody, { children: statusLoading ? (_jsx(TPProgressModal, { isLoading: statusLoading })) : complianceData.length > 0 ? (complianceData === null || complianceData === void 0 ? void 0 : complianceData.map(function (row) {
                                var _a, _b, _c;
                                return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ className: 'compliance-table-cell', sx: {
                                                borderRight: '1px solid #EAECF0',
                                                fontSize: fontSizeDynamic(14),
                                            }, id: "compliance-table-cell-".concat(row.name), "data-testid": "compliance-table-cell-".concat(row.name) }, { children: row.name })), _jsx(TableCell, __assign({ className: 'compliance-table-cell', align: 'left', id: "compliance-table-cell-".concat(row.validFrom), "data-testid": "compliance-table-cell-".concat(row.validFrom), sx: { fontSize: fontSizeDynamic(14) } }, { children: row.validFrom
                                                ? dayjs.utc(row.validFrom).format('MM/DD/YYYY')
                                                : 'NA' })), _jsx(TableCell, __assign({ className: 'compliance-table-cell', align: 'left', id: "compliance-table-cell-".concat(row.validTo), "data-testid": "compliance-table-cell-".concat(row.validTo), sx: { fontSize: fontSizeDynamic(14) } }, { children: row.validTo
                                                ? dayjs.utc(row.validTo).format('MM/DD/YYYY')
                                                : 'NA' })), _jsx(TableCell, __assign({ className: 'compliance-table-cell', align: 'left', id: "compliance-table-cell-".concat(row.status), "data-testid": "compliance-table-cell-".concat(row.status), sx: { fontSize: fontSizeDynamic(14) } }, { children: _jsxs(Box, __assign({ className: 'compliance-table-status', sx: {
                                                    color: setColors(row.status, colorCodes.genericColors),
                                                } }, { children: [_jsx(TPSvgIcon, { resourceName: 'circle-icon', width: 8, height: 8, className: 'compliance-circle-dot-icon', background: setColors(row.status, colorCodes.genericColors) }), _jsx(Typography, __assign({ className: 'compliance-status', fontSize: fontSizeDynamic(14) }, { children: row.status }))] })) })), _jsx(TableCell, __assign({ className: 'compliance-table-cell', align: 'left', id: "compliance-table-cell-".concat(row.lastUpdatedOn), "data-testid": "compliance-table-cell-".concat(row.lastUpdatedOn), sx: { fontSize: fontSizeDynamic(14) } }, { children: !((_a = row === null || row === void 0 ? void 0 : row.evidence) === null || _a === void 0 ? void 0 : _a.sharepointUrl)
                                                ? 'NA'
                                                : row.lastUpdatedOn
                                                    ? dayjs.utc(row.lastUpdatedOn).format('MM/DD/YYYY')
                                                    : 'NA' })), isHideDownload && (_jsx(TableCell, __assign({ className: 'compliance-table-cell', align: 'left' }, { children: ((_b = row === null || row === void 0 ? void 0 : row.evidence) === null || _b === void 0 ? void 0 : _b.sharepointUrl) ? (_jsx("img", { src: downloadImg, alt: 'download', className: 'compliance-download-img', id: "download-".concat(row.id), "data-testid": "download-".concat(row.id), onClick: function () {
                                                    var _a;
                                                    return downloadEvidence(row.id, (_a = row === null || row === void 0 ? void 0 : row.evidence) === null || _a === void 0 ? void 0 : _a.sharepointUrl);
                                                } })) : (_jsx("img", { src: downloadDisabledImg, alt: 'download-disabled', className: 'compliance-download-img', id: "download-disabled-".concat(row.id), "data-testid": "download-disabled-".concat(row.id) })) }))), _jsx(TableCell, __assign({ className: 'compliance-table-cell', sx: {
                                                borderLeft: '1px solid #EAECF0',
                                                fontSize: fontSizeDynamic(14),
                                            } }, { children: ((_c = row === null || row === void 0 ? void 0 : row.evidence) === null || _c === void 0 ? void 0 : _c.sharepointUrl) ? (_jsx(TPButton, __assign({ className: 'compliance-btn', color: 'primary', id: "re-submit-btn-".concat(row.id), "data-testid": "re-submit-btn-".concat(row.id), onClick: function () {
                                                    return submitHandler(row.name, row.validFrom, row.validTo, row.status, row.id);
                                                }, disabled: row.status === 'Checked', sx: { fontSize: fontSizeDynamic(14) } }, { children: "Re-submit" }))) : (_jsx(TPButton, __assign({ className: 'compliance-btn', color: 'primary', id: "submit-btn-".concat(row.id), "data-testid": "submit-btn-".concat(row.id), onClick: function () {
                                                    return submitHandler(row.name, row.validFrom, row.validTo, row.status, row.id);
                                                }, disabled: row.status === 'Checked', sx: { fontSize: fontSizeDynamic(14) } }, { children: "Submit" }))) }))] }, row.id));
                            })) : (_jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: placementComplianceColumns.length, sx: { borderBottom: 'none' } }, { children: _jsx(Typography, __assign({ className: 'no-data', id: 'no-data', "data-testid": 'no-data', fontSize: fontSizeDynamic(16) }, { children: "No data found" })) })) })) })] })) })), isLoading && (_jsx(TPCustomModal, { open: isLoading, DialogContentProps: {
                    sx: { p: '26px 36px 26px 37px' },
                }, DialogActionsProps: {
                    sx: { padding: '0px' },
                }, slotProps: {
                    backdrop: {
                        style: { backdropFilter: 'none' },
                    },
                }, children: _jsx(Evidence, { placementId: placementId, evidenceObj: evidenceObj, setIsLoading: setIsLoading, successMessage: successMessage, setSuccessMessage: setSuccessMessage, setErrorMessage: setErrorMessage, fetchComplianceData: fetchCompliance }) }))] })));
};
export default Compliance;
