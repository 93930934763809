var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import router from './RouterConfig';
import './App.css';
var theme = createTheme({
    typography: {
        fontFamily: '"Public Sans",sans-serif',
    },
});
function App() {
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(PersistGate, __assign({ loading: null, persistor: persistor }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(RouterProvider, { router: router }) })) })) })));
}
export default App;
