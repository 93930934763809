var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import TPCustomModal from '../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPProgressModal from '../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import classes from './Home.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../tandym-web-common/src/shared/services/api/API';
import CandidateInfo from '../components/candidateinfo/CandidateInfo';
import CandidateUpdate from '../components/candidateupdate/CandidateUpdate';
import { TANDYM_CONSTANTS } from '../shared/Constants';
import { setLogIn, setLogOut } from '../store/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { setEntitlements } from '../store/entitlementSlice';
import { jwtDecode } from 'jwt-decode';
import { setTabNavigationGuard } from '../store/tabNavigationGuard';
import { setCollaborativeCoaching, setPII } from '../store/profileSlice';
import { fontSizeDynamic } from '../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import ReactGA from 'react-ga4';
import TPToast from '../../../tandym-web-common/src/shared/components/toast/TPToast';
import TermsAndConditions from './termsAndConditions/TermsAndConditions';
var Home = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(null), firstName = _e[0], setFirstName = _e[1];
    var _f = useState(null), lastName = _f[0], setLastName = _f[1];
    var _g = useState([]), errorMessages = _g[0], setErrorMessages = _g[1];
    var acceptTermsURL = props.acceptTermsURL;
    var dispatch = useAppDispatch();
    var _h = useAppSelector(function (state) { return state.userAuth; }), authToken = _h.authToken, email = _h.email, googleTrackingId = _h.googleTrackingId, isTermsAccepted = _h.isTermsAccepted;
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _j = useState(false), isLoading = _j[0], setIsLoading = _j[1];
    var _k = useState(false), checkbox = _k[0], setCheckbox = _k[1];
    var _l = useState(null), profileData = _l[0], setProfileData = _l[1];
    var location = useLocation();
    var _m = useAPI(), httpGet = _m.httpGet, httpPost = _m.httpPost;
    var navigate = useNavigate();
    useEffect(function () {
        if (authToken && isTermsAccepted) {
            fetchAllData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(function () {
        if (googleTrackingId) {
            ReactGA.initialize(googleTrackingId, {
                gaOptions: {
                    cookie_flags: 'SameSite=None; Secure',
                    anonymizeIp: true, // Masks user IPs for additional privacy
                },
            });
        }
    }, [googleTrackingId]);
    var avtarNameValidation = function (firstName, lastName) {
        var firstNameValidation = (firstName === null || firstName === void 0 ? void 0 : firstName.trim().charAt(0)) || '';
        var lastNameValidation = (lastName === null || lastName === void 0 ? void 0 : lastName.trim().charAt(0)) || '';
        if (firstName && lastName) {
            return "".concat(firstNameValidation).concat(lastNameValidation);
        }
        else if (firstName) {
            return "".concat(firstNameValidation);
        }
        return '';
    };
    sessionStorage.setItem('userInitials', avtarNameValidation(firstName, lastName));
    useEffect(function () {
        var _a, _b;
        if (authToken) {
            try {
                var decodedToken = jwtDecode(authToken);
                setFirstName((_a = decodedToken.FirstName) !== null && _a !== void 0 ? _a : null);
                setLastName((_b = decodedToken.LastName) !== null && _b !== void 0 ? _b : null);
                dispatch(setLogIn({
                    firstName: decodedToken.FirstName,
                    lastName: decodedToken.LastName,
                }));
            }
            catch (error) {
                console.error('Invalid token:', error);
            }
        }
        else {
            console.error('Invalid authToken:', authToken);
        }
    }, [authToken]);
    useEffect(function () {
        if (errorMessages.length > 0) {
            var timeout_1 = setTimeout(function () {
                setErrorMessages([]);
            }, 3000); // Clear errors after 3 seconds
            return function () { return clearTimeout(timeout_1); };
        }
    }, [errorMessages]);
    var handleAcceptTerms = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var otp, response, _a, Email, FirstName, LastName, error_1, errorMsg_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    otp = sessionStorage.getItem('otp');
                    return [4 /*yield*/, httpPost(acceptTermsURL, null, {
                            email: email,
                            code: otp,
                        })];
                case 1:
                    response = _b.sent();
                    if (!response.error) {
                        _a = jwtDecode(response.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName;
                        dispatch(setLogIn({
                            candidateId: response.mercuryContactId,
                            isLoggedIn: true,
                            email: Email,
                            authToken: response.authToken,
                            firstName: FirstName,
                            lastName: LastName,
                            googleTrackingId: response.googleTrackingId,
                            NajaxaTimeSheetURL: response.fEEnvironment.NajaxaTimeSheetURL,
                            paycheckURL: response.fEEnvironment.PaycheckURL,
                            taskHumanURL: response.fEEnvironment.TaskHumanURL,
                            isTermsAccepted: true,
                        }));
                        sessionStorage.setItem('authToken', response.authToken);
                        setTimeout(function () {
                            fetchAllData();
                        }, 500);
                    }
                    else {
                        throw new Error(response.message || 'Login link is not valid');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _b.sent();
                    errorMsg_1 = error_1.message || 'Failed to accept terms.';
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [errorMsg_1], false); });
                    sessionStorage.clear();
                    setTimeout(function () {
                        navigate('/login', { replace: true });
                        dispatch(setLogOut());
                    }, 2000);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [checkbox, dispatch, email, httpPost, acceptTermsURL]);
    var fetchCandidateInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_PROFILE_GET)];
                case 1:
                    response = _c.sent();
                    if (!response.error) {
                        dispatch(setTabNavigationGuard({
                            mainTabNavigationGuard: false,
                        }));
                        dispatch(setCollaborativeCoaching({
                            isCollaborativeCoaching: (_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.isCollaborativeCoaching,
                        }));
                        dispatch(setPII({
                            isPII: (_b = response === null || response === void 0 ? void 0 : response.response) === null || _b === void 0 ? void 0 : _b.isPII,
                        }));
                        setProfileData(response.response);
                    }
                    else {
                        throw new Error(response.message);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _c.sent();
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        (error_2 === null || error_2 === void 0 ? void 0 : error_2.message) || 'Failed to fetch candidate info.',
                    ], false); });
                    console.error('Fetch candidate info error:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchEntitlements = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_ENTITLEMENTS_GET)];
                case 1:
                    response = _a.sent();
                    if (!response.error) {
                        dispatch(setEntitlements({
                            entitlementsData: response,
                        }));
                        sessionStorage.setItem('timeSheetEntitlements', response.entitlementDto.timesheets);
                    }
                    else {
                        navigate('/');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        (error_3 === null || error_3 === void 0 ? void 0 : error_3.message) || 'Failed to fetch entitlements.',
                    ], false); });
                    console.error('Fetch entitlements error:', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchAllData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, Promise.allSettled([
                            fetchCandidateInfo(),
                            fetchEntitlements(),
                        ]).finally(function () {
                            setIsLoading(false);
                        })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        sessionStorage.clear();
        navigate('/');
    };
    var isMobileScreen = window.innerWidth <= 768;
    return (_jsxs(Box, __assign({ sx: { width: '100%', my: '2.15rem' }, id: 'home', "data-testid": 'home' }, { children: [_jsx(TPToast, { message: errorMessages.join(' '), open: errorMessages.length > 0, vertical: 'top', horizontal: 'center', type: 'error' }), _jsx(Box, __assign({ sx: { px: { xs: '0.625rem', md: '2.938rem' } } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ sx: { display: isMobileScreen ? 'none' : 'block' }, item: true, xs: 0, sm: 4, md: 4, lg: 3, id: 'candidate-info-grid' }, { children: _jsx(CandidateInfo, { profileData: profileData, showUploadResume: ((_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.showUploadResume)
                                    ? (_b = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _b === void 0 ? void 0 : _b.showUploadResume
                                    : false }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: isMobileScreen ? 12 : 8, md: 8, lg: 9, id: 'candidate-update-grid' }, { children: _jsx(CandidateUpdate, { profileData: profileData, entitlementsData: entitlementsData, showUploadResume: ((_c = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _c === void 0 ? void 0 : _c.showUploadResume)
                                    ? (_d = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _d === void 0 ? void 0 : _d.showUploadResume
                                    : false }) }))] })) })), _jsxs(Box, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(TPCustomModal, { id: 'terms-and-conditions', "data-testid": 'terms-and-conditions', title: 'Terms and Conditions', PaperProps: { sx: { maxWidth: '50rem', height: '70vh' } }, slotProps: { backdrop: { style: { backdropFilter: 'blur(5px)' } } }, DialogTitleProps: { sx: { p: '16px 45px' } }, cancelText: 'Cancel', confirmText: 'I Accept', open: !isTermsAccepted, children: _jsx(TermsAndConditions, {}), DialogContentProps: { className: classes.scrollbar }, confirmButtonProps: { className: classes.confirmButton }, DialogActionsProps: {
                            className: classes.dialogAction,
                            sx: {
                                m: '0px 10px 0px 32px',
                                fontSize: fontSizeDynamic(21),
                                flexWrap: { xs: 'wrap' },
                                gap: { xs: '5px' },
                            },
                        }, checkboxLabel: 'I confirm that I have read and accept the terms and conditions and privacy policy.', onClose: handleClose, checkboxChecked: checkbox, onCheckboxChange: setCheckbox, onConfirm: handleAcceptTerms })] })] })));
};
export default Home;
