var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import { Outlet } from 'react-router-dom';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import './LearningPathways';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var LearningTab = function () {
    var learning = 'learning';
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    var _b = useState(true), renderComponent = _b[0], setRenderComponent = _b[1];
    useEffect(function () {
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'LEARNING PATHWAYS',
                    path: '/learning/learning-pathways',
                    show: true,
                },
                {
                    label: 'TANDYM COACH',
                    path: '/learning/tandym-coach',
                    show: true,
                },
                {
                    label: 'LEARNING RESOURCES',
                    path: '/learning/learning-resources',
                    show: true,
                },
            ]);
            setRenderComponent(entitlementsData.entitlementDto.learning);
        }
    }, [entitlementsData]);
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "Candidate Learning ".concat(label, " tab"),
        });
    };
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    return (_jsxs(Box, __assign({ className: 'learning sub-route-section' }, { children: [_jsx(Grid, __assign({ container: true, direction: 'row', className: 'learning-header' }, { children: _jsx(Grid, __assign({ item: true, display: 'flex', justifyContent: 'space-between' }, { children: _jsxs(Box, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'sub-routes-header', id: "".concat(learning, "-header"), "data-testid": "".concat(learning, "-header"), fontSize: fontSizeDynamic(24) }, { children: "Learning Center" }))] }) })) })), _jsx(Box, __assign({ id: "".concat(learning, "-tab"), "data-testid": "".concat(learning, "-tab"), className: 'sub-tab-header-section' }, { children: _jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab }) })), _jsx(Outlet, {})] })));
};
export default LearningTab;
