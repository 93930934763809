import collaborativeCoachingIconImg from '../../images/collaborativeCoachingIconImg.png';
import communicationIconImg from '../../images/larning_communicationimg.png';
import leadershipImg from '../../images/learning-LeadershipImg.png';
import mindSet from '../../images/learning-mindsetImg.png';
import problemSolving from '../../images/learning_problemSolving.png';
import careerTransition from '../../images/learning_careerTransition.png';
import cultureAwareness from '../../images/learning_cultureAwareness.png';
import productivity from '../../images/learning_productivity.png';
export var collaborativeCoachingObject = [
    {
        id: 'collaborativeCoaching',
        imgSrc: collaborativeCoachingIconImg,
        title: 'Collaborative Coaching',
        description: "Tailored guidance to help temps identify career goals, map out\n                  career paths, and access resources for upskilling.",
    },
    {
        id: 'ProblemSolving+CriticalThinking',
        imgSrc: problemSolving,
        title: 'Problem Solving + Critical Thinking',
        description: "Enhances temps\u2019 critical thinking and problem-solving skills,\n                  allowing them to address workplace challenges independently\n                  and proactively contribute to organizational success.",
    },
    {
        id: 'communication+collaboration',
        imgSrc: communicationIconImg,
        title: 'Communication + Collaboration',
        description: "Improves communication abilities, leading to better teamwork\n                  and smoother integration into client environments.",
    },
    {
        id: 'careerTransition+workforceIntegration',
        imgSrc: careerTransition,
        title: 'Career Transition + Workforce Integration',
        description: "Aids in transitioning between temporary roles and adapting to\n                  new work environments and workplace culture, reducing the\n                  learning curve and increasing temp worker satisfaction.",
    },
    {
        id: 'leadership+influence',
        imgSrc: leadershipImg,
        title: 'Leadership + Influence',
        description: "Helps temps build leadership and negotiation skills, preparing\n                  them to step into leadership roles, even within temporary\n                  assignments, increasing their value to clients.",
    },
    {
        id: 'culturalAwareness+sensitivity',
        imgSrc: cultureAwareness,
        title: 'Cultural Awareness + Sensitivity',
        description: "Provides cultural sensitivity training, enabling temps to work\n                  effectively in diverse teams and understand workplace\n                  dynamics, fostering a more inclusive and respectful work\n                  environment.",
    },
    {
        id: 'mindset+adaptation',
        imgSrc: mindSet,
        title: 'Mindset + Adaptation',
        description: "Cultivates a growth mindset, boosting self-confidence, and\n                  developing emotional intelligence, improving adaptability and\n                  performance in dynamic work environments.",
    },
    {
        id: 'Productivity+efficiency',
        imgSrc: productivity,
        title: 'Productivity + Efficiency',
        description: "Productivity techniques helps temps manage time, prioritize\n                  tasks, and increase efficiency, leading to  better outcomes for worker and the client.",
    },
];
export var learningResourcesObject = [
    {
        title: 'Udemy',
        description: 'Get courses across 13 categories to help you learn new skills and accelerate your career without spending hours on finding the best teacher',
        buttonText: 'Visit Udemy',
        link: 'https://www.udemy.com/',
    },
    {
        title: 'Coursera',
        description: 'Get to learn from the professors of the best universities on earth on Coursera',
        buttonText: 'Visit Coursera',
        link: 'https://www.coursera.org/',
    },
    {
        title: 'Khan Academy',
        description: 'It offers impressive depth on many different subjects. The lectures are short and share a handful of information at a time, but build on each other as you progress.',
        buttonText: 'Visit Khan Academy',
        link: 'https://www.khanacademy.org/',
    },
    {
        title: 'MIT OpenCourseWare',
        description: 'It is a web-based publication of virtually all MIT course content. OCW is open and available to the world and is a permanent MIT activity',
        buttonText: 'Visit MIT OpenCourseWare',
        link: 'https://ocw.mit.edu/',
    },
    {
        title: 'FreeCodeCamp',
        description: 'You can learn to code for FREE. You can also build projects and earn certifications.More than 40,000 graduates have got jobs at tech companies.',
        buttonText: 'Visit FreeCodeCamp',
        link: 'https://www.freecodecamp.org/',
    },
    {
        title: 'Skillshare',
        description: 'Get free 7 days of trial to learn from a free course and master a skill',
        buttonText: 'Visit Skillshare',
        link: 'https://www.skillshare.com/en/',
    },
    {
        title: 'Harvard',
        description: 'A Step-by-step instruction on the “how to” pdf should be attached on the procedure.',
        buttonText: 'Visit Harvard',
        link: 'https://pll.harvard.edu/catalog/free',
    },
    {
        title: 'Hubspot Academy',
        description: 'Learn everything you need to know about the most sought-after business skills.',
        buttonText: 'Visit Hubspot',
        link: 'https://academy.hubspot.com/',
    },
    {
        title: 'Alison',
        description: 'Get free online courses with certifications and diplomas across a wide variety of domains',
        buttonText: 'Visit Alison',
        link: 'https://alison.com/',
    },
    {
        title: "Google's FREE Courses",
        description: 'Develop skills you can apply immediately from the tech leader of the world, with e-learning courses designed by Google product experts. Learn at your own pace and get Google product certified.',
        buttonText: "Visit Google's FREE Courses",
        link: 'https://skillshop.withgoogle.com/',
    },
];
