var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Box, FormControl, Grid, MenuItem, Select, Typography, } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import './placements.scss';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import ReactGA from 'react-ga4';
export var PlacementApiContext = createContext({
    placementInfo: null,
    placementId: null,
    isLoading: false,
});
export var usePlacementApiContext = function () {
    var context = useContext(PlacementApiContext);
    if (!context) {
        throw new Error('PlacementApiContext must be used within a PlacementApiProvider');
    }
    return context;
};
var Placements = function () {
    var authToken = useAppSelector(function (state) { return state.userAuth; }).authToken;
    var httpGet = useAPI().httpGet;
    var placementId = 'placement';
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(null), dropdownList = _b[0], setDropdownList = _b[1];
    var _c = useState(''), selectedPlacement = _c[0], setSelectedPlacement = _c[1];
    var _d = useState(), selectedPlacementOption = _d[0], setSelectedPlacementOption = _d[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _e = useState([]), tabs = _e[0], setTabs = _e[1];
    var _f = useState(true), renderComponent = _f[0], setRenderComponent = _f[1];
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    useEffect(function () {
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'PLACEMENT DETAILS',
                    path: '/placements/placements-details',
                    show: entitlementsData.entitlementDto.placement,
                },
                {
                    label: 'PLACEMENT COMPLIANCE',
                    path: '/placements/compliance',
                    show: entitlementsData.entitlementDto.placement,
                },
            ]);
            setRenderComponent(entitlementsData.entitlementDto.placement);
        }
    }, [entitlementsData]);
    var _h = useState(null), placementData = _h[0], setPlacementData = _h[1];
    useEffect(function () {
        fetchPlacementDropdwon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        setIsLoading(true);
        if (selectedPlacement !== '') {
            fetchPlacementInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlacement]);
    var fetchPlacementDropdwon = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getPlacementsByIdURL, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    getPlacementsByIdURL = TANDYM_CONSTANTS.API_URLS.CANDIDATE_PLACEMENT_DROPDOWN;
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(getPlacementsByIdURL)];
                case 1:
                    response = _a.sent();
                    if (response) {
                        setDropdownList(response.response);
                        setSelectedPlacement(response.response[0].placementid);
                        setSelectedPlacementOption(response.response[0]);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchPlacementInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getCandidatePlacementByIdURL, placementId_1, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (!authToken) return [3 /*break*/, 2];
                    getCandidatePlacementByIdURL = TANDYM_CONSTANTS.API_URLS.CANDIDATE_PLACEMENT_GET;
                    placementId_1 = selectedPlacement;
                    return [4 /*yield*/, httpGet(getCandidatePlacementByIdURL, {
                            placementId: placementId_1,
                        })];
                case 1:
                    response = _a.sent();
                    if (response) {
                        setPlacementData(response.response);
                    }
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.log(error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (event) {
        setSelectedPlacement(event.target.value);
        setSelectedPlacementOption(dropdownList === null || dropdownList === void 0 ? void 0 : dropdownList.find(function (item) { return item.placementid === event.target.value; }));
    };
    var handleDropdownOpen = function () {
        setOpen(true);
        document.body.style.overflow = 'hidden';
    };
    var handleDropdownClose = function () {
        setOpen(false);
        document.body.style.overflow = 'unset';
    };
    // Function to determine badge color based on the option
    var getBadgeColor = function (option) {
        if (option.statusReasonGroup === 'Live') {
            return '#27B500';
        }
        if (option.statusReasonGroup === 'Pending') {
            return '#FD820B';
        }
        return '#2A70EA';
    };
    // Function to determine badge icon based on the option
    var GetBadgeIcon = function (option) {
        if (option.option.statusReasonGroup === 'Live') {
            return _jsx(TPSvgIcon, { resourceName: 'status-live-icon', height: 8, width: 8 });
        }
        if (option.option.statusReasonGroup === 'Pending') {
            return _jsx(TPSvgIcon, { resourceName: 'status-pending-icon' });
        }
        return _jsx(TPSvgIcon, { resourceName: 'status-completed-icon' });
    };
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "Candidate Placements ".concat(label, " tab"),
        });
    };
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    return (_jsxs(PlacementApiContext.Provider, __assign({ value: {
            placementInfo: placementData !== null && placementData !== void 0 ? placementData : null,
            placementId: selectedPlacement,
            isLoading: isLoading,
        } }, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsxs(Box, __assign({ className: 'sub-route-section placement' }, { children: [_jsxs(Box, __assign({ className: "".concat(dropdownList && dropdownList.length > 0 ? 'withPlacement' : 'withoutPlacement') }, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(Grid, __assign({ container: true, direction: 'row' }, { children: _jsxs(Grid, __assign({ item: true, display: 'flex', justifyContent: 'space-between', xs: 12 }, { children: [_jsxs(Box, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'sub-routes-header', fontSize: fontSizeDynamic(24) }, { children: "Placements" }))] }), dropdownList && dropdownList.length > 0 && (_jsxs(Grid, __assign({ item: true, display: 'flex', mr: 4 }, { children: [_jsx(Typography, __assign({ className: 'placement-dropdown-label', fontSize: fontSizeDynamic(16) }, { children: "Placement :" })), _jsxs(Box, { children: [_jsx(FormControl, __assign({ id: "".concat(placementId, "-dropdown-control"), "data-testid": "".concat(placementId, "-dropdown-control"), variant: 'standard', defaultChecked: true, className: 'placement-dropdown', sx: { fontSize: fontSizeDynamic(16) } }, { children: _jsx(Select, __assign({ id: "".concat(placementId, "-dropdown"), "data-testid": "".concat(placementId, "-dropdown"), value: selectedPlacementOption &&
                                                                    selectedPlacementOption.placementid, onChange: handleChange, onOpen: handleDropdownOpen, onClose: handleDropdownClose, disableUnderline: true, MenuProps: { disableScrollLock: true }, sx: { fontSize: fontSizeDynamic(16) }, renderValue: function (selected) {
                                                                    var selectedOption = dropdownList.find(function (option) { return option.placementid === selected; });
                                                                    return selectedOption
                                                                        ? selectedOption.name
                                                                        : 'Select an option';
                                                                } }, { children: dropdownList &&
                                                                    (dropdownList === null || dropdownList === void 0 ? void 0 : dropdownList.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.placementid }, { children: _jsxs(Box, __assign({ sx: {
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                            } }, { children: [_jsx(Typography, __assign({ sx: { mb: '5px' }, fontSize: fontSizeDynamic(16) }, { children: item.name })), open && (_jsxs(Typography, __assign({ style: {
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    } }, { children: [_jsx(Badge, { badgeContent: _jsxs(Box, __assign({ sx: {
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                } }, { children: [_jsx(GetBadgeIcon, { option: item }), item.statusReasonGroup] })), sx: {
                                                                                                '& .MuiBadge-badge ': {
                                                                                                    color: '#fff',
                                                                                                    backgroundColor: getBadgeColor(item),
                                                                                                    position: 'relative',
                                                                                                    transform: 'unset',
                                                                                                },
                                                                                            } }), _jsx(Typography, __assign({ sx: {
                                                                                                ml: '10px',
                                                                                                color: getBadgeColor(item),
                                                                                            }, fontSize: fontSizeDynamic(16) }, { children: item.startDate }))] })))] })) }), item.placementid)); })) })) })), _jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'end' } }, { children: selectedPlacementOption && (_jsx(Badge, { badgeContent: _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(GetBadgeIcon, { option: selectedPlacementOption }), selectedPlacementOption &&
                                                                            selectedPlacementOption.statusReasonGroup] })), sx: {
                                                                    '& .MuiBadge-badge ': {
                                                                        color: '#fff',
                                                                        backgroundColor: getBadgeColor(selectedPlacementOption),
                                                                        position: 'relative',
                                                                        transform: 'unset',
                                                                        fontSize: fontSizeDynamic(10),
                                                                    },
                                                                } })) }))] })] })))] })) }))] })), _jsx(Box, __assign({ id: "".concat(placementId, "-tab"), "data-testid": "".concat(placementId, "-tab"), className: 'sub-tab-header-section' }, { children: _jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab }) })), _jsx(Outlet, {})] }))] })));
};
export default Placements;
