var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './ContactPreference.scss';
import Switch from './Switch';
import TPButton from '../../../../../../tandym-web-common/src/shared/components/button/TPButton';
import { useAPI } from '../../../../../../tandym-web-common/src/shared/services/api/API';
import { useAppSelector } from '../../../../store/hook';
import { ContactTypes } from '../../../../__mocks__/Preference.mock';
import { TANDYM_CONSTANTS } from '../../../../shared/Constants';
import TPProgressModal from '../../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { fontSizeDynamic } from '../../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var ContactPreference = function (props) {
    var onClick = props.onClick, successMessage = props.successMessage, errorMessage = props.errorMessage;
    var _a = useState(null), contactData = _a[0], setContactData = _a[1];
    var _b = useState(true), btnDisabled = _b[0], setBtnDisabled = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState({}), switchStates = _d[0], setSwitchStates = _d[1];
    var _e = useAPI(), httpGet = _e.httpGet, httpPost = _e.httpPost;
    var candidateId = useAppSelector(function (state) { return state.userAuth; }).candidateId;
    var handleSwitchChange = function (id, state) {
        setSwitchStates(function (prevStates) {
            var _a;
            return (__assign(__assign({}, prevStates), (_a = {}, _a[id] = state, _a)));
        });
        setBtnDisabled(false);
    };
    var getContactPreference = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, contactPref, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CONTACT_PREFERENCE_GET, { candidateId: candidateId })];
                case 2:
                    response = _a.sent();
                    if (response.error === false && response.code === 'TM_CONTACT_001') {
                        contactPref = response.response;
                        setContactData(contactPref);
                        setSwitchStates({
                            newJobInformation: contactPref.newJobInformation,
                            callMe: contactPref.callMe,
                            sendatextmessage: contactPref.sendatextmessage,
                            receiveEmail: contactPref.receiveEmail,
                        });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Failed to fetch contact preferences:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var updateContactPreference = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    data = {
                        newJobInformation: !switchStates.newJobInformation,
                        callMe: !switchStates.callMe,
                        sendTextMessage: !switchStates.sendatextmessage,
                        receiveEmail: !switchStates.receiveEmail,
                    };
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CONTACT_PREFERENCE_UPDATE, data, {
                            candidateId: candidateId,
                        })];
                case 2:
                    response = _a.sent();
                    if (response.error === false && response.code === 'TM_CONTACT_002') {
                        setBtnDisabled(true);
                        onClick(false);
                        successMessage(response.message);
                    }
                    if (response.status === 400) {
                        onClick(false);
                        errorMessage(response.title);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error('Failed to update contact preferences:', error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var convertToCamelCase = function (input) {
        return input.replace('-', ' ').replace(/\s+/g, '').toLowerCase();
    };
    useEffect(function () {
        getContactPreference();
    }, []);
    return (_jsxs(Box, __assign({ className: 'contact-preference', id: 'contact-preference', "data-testid": 'contact-preference' }, { children: [_jsx(Typography, __assign({ className: 'contact-preference-text', id: 'contact-preference-text', "data-testid": 'contact-preference-text', fontSize: fontSizeDynamic(24) }, { children: "Contact Preference" })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: '0.188rem', mt: '0.75rem' } }, { children: [_jsx(Typography, __assign({ className: 'note', id: 'note', "data-testid": 'note', fontSize: fontSizeDynamic(14) }, { children: "Note:" })), _jsx(Typography, __assign({ className: 'note-info', id: 'note-info', "data-testid": 'note-info', fontSize: fontSizeDynamic(14) }, { children: "Please select how you wish to be contacted." }))] })), _jsx(Box, __assign({ sx: { mt: '3.188rem' } }, { children: contactData &&
                    Object.keys(contactData).map(function (key) {
                        var _a;
                        var label = (_a = ContactTypes.find(function (type) { return convertToCamelCase(type.label) === key.toLowerCase(); })) === null || _a === void 0 ? void 0 : _a.label;
                        var isActive = switchStates[key];
                        return (_jsxs(Grid, __assign({ container: true, justifyContent: 'space-between', spacing: 2, sx: { mt: '0.813rem' } }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ className: 'contact-type', id: key, "data-testid": key, fontSize: fontSizeDynamic(16) }, { children: label })) })), _jsx(Grid, __assign({ item: true }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ id: "".concat(key, "-no-contact-option"), "data-testid": "".concat(key, "-no-contact-option"), className: "contact-option ".concat(!isActive ? 'active-switch' : ''), fontSize: fontSizeDynamic(14) }, { children: "No" })), _jsx(Switch, { label: key, open: isActive, onClick: function (state) { return handleSwitchChange(key, state); } }), _jsx(Typography, __assign({ id: "".concat(key, "-yes-contact-option"), "data-testid": "".concat(key, "-yes-contact-option"), className: "contact-option ".concat(isActive ? 'active-switch' : '') }, { children: "Yes" }))] })) }))] }), key));
                    }) })), _jsx(Grid, __assign({ container: true, direction: 'row', justifyContent: 'end', sx: { mt: '3rem' } }, { children: _jsxs(Grid, __assign({ item: true }, { children: [_jsx(TPButton, __assign({ onClick: function () { return onClick(false); }, id: 'cancel-button', "data-testid": 'cancel-button', color: 'secondary', sx: { mr: '1.88rem', fontSize: fontSizeDynamic(14) } }, { children: "Cancel" })), _jsx(TPButton, __assign({ id: 'save-preference-button', "data-testid": 'save-preference-button', disabled: btnDisabled, color: 'primary', onClick: updateContactPreference, sx: { fontSize: fontSizeDynamic(14) } }, { children: "Save Preference" }))] })) })), isLoading && _jsx(TPProgressModal, { isLoading: isLoading })] })));
};
export default ContactPreference;
