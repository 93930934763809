import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
export var skillsCss = {
    tableGrid: {
        boxShadow: 'none !important',
    },
    buttonContainer: { gap: 2, mt: 10 },
    rowEditAndDelete: {
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        cursor: 'pointer',
    },
    plusIconTable: {
        textAlign: '-webkit-center',
        cursor: 'pointer',
        paddingLeft: 6,
    },
    tableItemText: {
        fontSize: fontSizeDynamic(16),
        fontWeight: 300,
        color: colorCodes.textColors.black,
    },
    tableHeaderText: {
        color: colorCodes.textColors.lightGray,
        fontSize: fontSizeDynamic(16),
        fontWeight: 300,
    },
    noData: {
        color: colorCodes.textColors.black,
        fontSize: fontSizeDynamic(20),
        fontWeight: 200,
        textAlign: 'center',
    },
};
