var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography } from '@mui/material';
import TPButton from '../../../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPCustomModal from '../../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import './DeleteSkill.scss';
import TPSvgIcon from '../../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var DeleteConfirmationModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, onConfirm = _a.onConfirm;
    return (_jsx(TPCustomModal, __assign({ id: 'delete-confirmation-modal', "data-testid": 'delete-confirmation-modal', open: open, PaperProps: {
            sx: {
                minWidth: '27.125rem',
                minHeight: '10.75rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0rem',
                borderRadius: 1,
            },
        }, slotProps: {
            backdrop: { style: { backdropFilter: 'none !important' } },
        }, DialogActionsProps: {
            sx: { padding: '0px' },
        } }, { children: _jsxs(Box, __assign({ className: 'delete-skill', id: 'delete-skill', "data-testid": 'delete-skill' }, { children: [_jsx(Typography, __assign({ className: 'delete-text' }, { children: _jsx(TPSvgIcon, { resourceName: 'Delete-imag-icon', height: 15 }) })), _jsx(Typography, __assign({ className: 'delete-skill-text', fontSize: fontSizeDynamic(24) }, { children: "Delete" })), _jsx(Typography, __assign({ className: 'top-left-typographies', fontSize: fontSizeDynamic(14) }, { children: "Are you sure you want to delete this?" })), _jsx(Typography, __assign({ className: 'top-left-typographies', fontSize: fontSizeDynamic(14) }, { children: "This action cannot be undone after saving skills." })), _jsxs(Box, __assign({ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }, { children: [_jsx(TPButton, __assign({ onClick: onClose, color: 'secondary', sx: { fontSize: fontSizeDynamic(14) } }, { children: "Cancel" })), _jsx(TPButton, __assign({ onClick: onConfirm, color: 'error', sx: { fontSize: fontSizeDynamic(14) } }, { children: "Delete" }))] }))] })) })));
};
export default DeleteConfirmationModal;
