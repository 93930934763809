export var complianceColumns = [
    { id: 'status', label: 'STATUS', align: 'left' },
    { id: 'evidence', label: 'EVIDENCE', align: 'left' },
    {
        id: 'validFrom',
        label: 'VALID FROM',
        align: 'left',
    },
    {
        id: 'validTo',
        label: 'VALID TO',
        align: 'left',
    },
    {
        id: 'description',
        label: 'DESCRIPTION',
        align: 'left',
    },
    {
        id: 'actions',
        label: 'ACTIONS',
    },
];
export var complianceRows = [
    {
        id: 1,
        status: 'submitted',
        evidence: 'diploma',
        validFrom: '07/10/2024',
        validTo: '07/18/2024',
        description: 'diploma ',
        actions: 'edit',
    },
    {
        id: 1,
        status: 'submitted',
        evidence: 'Employers Liability Insurance',
        validFrom: '07/10/2024',
        validTo: '07/18/2024',
        description: 'Employers Liability Insurance ',
        actions: 'edit',
    },
];
export var placementComplianceColumns = [
    { id: 'name', label: 'CRITERIA', align: 'left' },
    {
        id: 'validFrom',
        label: 'VALID FROM',
        align: 'left',
    },
    {
        id: 'validTo',
        label: 'VALID TO',
        align: 'left',
    },
    {
        id: 'status',
        label: 'STATUS',
        align: 'left',
    },
    {
        id: 'lastUpdatedOn',
        label: 'SUBMITTED DATE',
        align: 'left',
    },
    {
        id: 'evidence',
        label: 'EVIDENCE',
        align: 'left',
    },
    {
        id: 'action',
        label: '',
        align: 'left',
    },
];
export var ComplianceMock = [
    {
        id: 'fe832fbf-925d-ef11-bfe3-000d3a9bc225',
        name: 'Placements',
        status: 'Submitted',
        validFrom: '2024-08-20T00:00:00Z',
        validTo: '2024-08-21T00:00:00Z',
        checkedBy: null,
        checkedOn: null,
        lastUpdatedOn: '2024-08-19T15:46:31Z',
        evidence: {
            id: 'e71155c5-925d-ef11-bfe3-000d3a9bc225',
            name: 'Placement Specific Compliance Test',
            comment: null,
            sharepointUrl: '/sites/Mercury/contact/Test Prakash/Compliance/file_2024-08-19_15-46-28.pdf',
        },
    },
    {
        id: 'fe832fbf-925d-ef11-bfe3-000d3a9bc226',
        name: 'Passport',
        status: 'Outstanding',
        validFrom: '2024-08-22T00:00:00Z',
        validTo: '2024-08-23T00:00:00Z',
        checkedBy: null,
        checkedOn: null,
        lastUpdatedOn: '2024-08-21T15:46:31Z',
        evidence: {
            id: 'e71155c5-925d-ef11-bfe3-000d3a9bc226',
            name: 'Passport',
            comment: null,
            sharepointUrl: '/sites/Mercury/contact/Test Prakash/Compliance/file_2024-08-21_15-46-28.pdf',
        },
    },
    {
        id: 'fe832fbf-925d-ef11-bfe3-000d3a9bc227',
        name: 'Cancelled Cheque',
        status: 'Submitted',
        validFrom: '2024-08-24T00:00:00Z',
        validTo: '2024-08-25T00:00:00Z',
        checkedBy: null,
        checkedOn: null,
        lastUpdatedOn: '2024-08-23T15:46:31Z',
        evidence: {
            id: 'e71155c5-925d-ef11-bfe3-000d3a9bc227',
            name: 'Cancelled Cheque',
            comment: null,
            sharepointUrl: '/sites/Mercury/contact/Test Prakash/Compliance/file_2024-08-23_15-46-28.pdf',
        },
    },
    {
        id: 'fe832fbf-925d-ef11-bfe3-000d3a9bc228',
        name: 'Property Documents',
        status: 'Outstanding',
        validFrom: '2024-08-26T00:00:00Z',
        validTo: '2024-08-27T00:00:00Z',
        checkedBy: null,
        checkedOn: null,
        lastUpdatedOn: '2024-08-25T15:46:31Z',
        evidence: {
            id: 'e71155c5-925d-ef11-bfe3-000d3a9bc228',
            name: 'Property Documents',
            comment: null,
            sharepointUrl: '/sites/Mercury/contact/Test Prakash/Compliance/file_2024-08-25_15-46-28.pdf',
        },
    },
    {
        id: 'fe832fbf-925d-ef11-bfe3-000d3a9bc229',
        name: 'Insurance Documents',
        status: 'Rejected',
        validFrom: '2024-08-28T00:00:00Z',
        validTo: '2024-08-29T00:00:00Z',
        checkedBy: null,
        checkedOn: null,
        lastUpdatedOn: '2024-08-27T15:46:31Z',
        evidence: {
            id: 'e71155c5-925d-ef11-bfe3-000d3a9bc229',
            name: 'Insurance Documents',
            comment: null,
            sharepointUrl: '/sites/Mercury/contact/Test Prakash/Compliance/file_2024-08-27_15-46-28.pdf',
        },
    },
];
