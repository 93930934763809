export var tabs = [
    { label: 'Home', path: '/home' },
    {
        label: 'Profile',
        path: '/profile',
    },
    { label: 'Placements', path: '/placements' },
    { label: 'Time and Expenses', path: '/timesheets-expenses' },
    { label: 'Pay', path: '/pay' },
    { label: 'Learning', path: '/learning' },
    { label: 'Info Center', path: '/info-center' },
];
