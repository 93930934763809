var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import './Faqs.scss';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
export var FaqContext = createContext(undefined);
export var useFaqContext = function () {
    var context = useContext(FaqContext);
    if (!context) {
        throw new Error('useFaqContext must be used within a FaqProvider');
    }
    return context;
};
var Faqs = function () {
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    var _b = useState(true), renderComponent = _b[0], setRenderComponent = _b[1];
    var _c = useState([]), indexValue = _c[0], setIndexValue = _c[1];
    var _d = useState(false), expand = _d[0], setExpand = _d[1];
    var smallScreen = window.innerWidth <= 450;
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'SICK TIME',
                    path: '/faqs/sick-time',
                    show: entitlementsData.entitlementDto.candidateInfoCenterJson
                        .sickTime,
                },
                {
                    label: 'TIMESHEET',
                    path: '/faqs/timesheet',
                    show: entitlementsData.entitlementDto.timesheets,
                },
                {
                    label: 'BENEFITS',
                    path: '/faqs/health-benefits',
                    show: entitlementsData.entitlementDto.fAQBenefitsAndInsurance,
                },
                { label: 'CONTACT DETAILS', path: '/info-center/contact', show: true },
            ]);
            setRenderComponent(entitlementsData.entitlementDto.candidateInfoCenterJson.sickTime);
        }
    }, [entitlementsData]);
    var handleClickedTab = function (label) {
        setIndexValue([]);
        setExpand(false);
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "Candidate Faq ".concat(label, " tab"),
        });
    };
    var handleExpend = function () {
        if (indexValue.length > 0) {
            setIndexValue([]);
            setExpand(false);
        }
        else {
            setExpand(true);
        }
    };
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsx(FaqContext.Provider, __assign({ value: { expand: expand, handleExpend: handleExpend, indexValue: indexValue, setIndexValue: setIndexValue } }, { children: _jsxs(Box, __assign({ className: 'sub-route-section info-center' }, { children: [_jsxs(Box, __assign({ sx: { mb: '20px' } }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'sub-routes-header', fontSize: fontSizeDynamic(24) }, { children: "Frequently Asked Questions" }))] })), _jsxs(Box, __assign({ className: 'sub-tab-header-section faq-expand-text' }, { children: [_jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab }), !smallScreen && (_jsx("span", __assign({ id: 'faqs-CollapseAllAndExpandAll', "data-testid": 'faqs-CollapseAllAndExpandAll', className: 'blue-text expend-all-btn', style: {
                                fontSize: fontSizeDynamic(20),
                            }, onClick: handleExpend }, { children: indexValue.length > 0 ? 'Collapse' : 'Expand' }))), smallScreen && (_jsx(Typography, __assign({ className: 'blue-text', style: {
                                fontSize: fontSizeDynamic(20),
                                cursor: 'pointer',
                                justifySelf: 'end',
                            }, onClick: handleExpend }, { children: "Expand" })))] })), _jsx(Box, __assign({ sx: { px: '5px', py: '10px' } }, { children: _jsx(Outlet, {}) }))] })) })));
};
export default Faqs;
