import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // Use sessionStorage instead of localStorage
import authSlice from './authSlice';
import entitlementSlice from './entitlementSlice';
import candidateTabNavigationGuardSlice from './tabNavigationGuard';
import profileSlice from './profileSlice';
var persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['userAuth', 'entitlements', 'tabNavigationGuard', 'userProfile'], // Specify which reducers to persist
};
var rootReducer = combineReducers({
    userAuth: authSlice,
    entitlements: entitlementSlice,
    tabNavigationGuard: candidateTabNavigationGuardSlice,
    userProfile: profileSlice,
});
export var persistedReducer = persistReducer(persistConfig, rootReducer);
export var store = configureStore({
    reducer: persistedReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
            immutableCheck: false,
        });
    },
});
export var persistor = persistStore(store);
