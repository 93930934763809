import { createSlice } from '@reduxjs/toolkit';
import { EntitlementsData } from '../__mocks__/Entitlements.mock';
var initialState = {
    entitlementsData: EntitlementsData
};
var entitlementSlice = createSlice({
    name: 'entitlements',
    initialState: initialState,
    reducers: {
        setEntitlements: function (state, action) {
            state.entitlementsData = action.payload.entitlementsData;
        }
    },
});
export var setEntitlements = entitlementSlice.actions.setEntitlements;
export default entitlementSlice.reducer;
