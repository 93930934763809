var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import './Profile.scss';
import Tabs from '../../../../tandym-web-common/src/components/Tabs';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import ReactGA from 'react-ga4';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { setTabNavigationGuard, setNavigationGuardModalPopup, setNavigationHistory, } from '../../store/tabNavigationGuard';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { setPII } from '../../store/profileSlice';
export var CandidateApiContext = createContext({
    profileData: null,
    refreshProfile: function () { },
});
export var useCandidateApiContext = function () {
    var context = useContext(CandidateApiContext);
    if (!context) {
        throw new Error('useCandidateApiContext must be used within a CandidateApiProvider');
    }
    return context;
};
var Profile = function () {
    var httpGet = useAPI().httpGet;
    var dispatch = useAppDispatch();
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _b = useState([]), tabs = _b[0], setTabs = _b[1];
    var _c = useState(true), renderComponent = _c[0], setRenderComponent = _c[1];
    var _d = useState(null), profileData = _d[0], setProfileData = _d[1];
    useEffect(function () {
        fetchCandidateInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'PERSONAL DETAILS',
                    path: '/profile/personal-details',
                    show: entitlementsData.entitlementDto.profileJson.personalDetails,
                },
                {
                    label: 'SUMMARY',
                    path: '/profile/summary',
                    show: entitlementsData.entitlementDto.profileJson.summary,
                },
                {
                    label: 'SKILLS',
                    path: '/profile/skills',
                    show: entitlementsData.entitlementDto.profileJson.skills,
                },
                {
                    label: 'PREFERENCE',
                    path: '/profile/preference',
                    show: entitlementsData.entitlementDto.profileJson.preference,
                },
            ]);
            setRenderComponent(entitlementsData.entitlementDto.profile);
        }
    }, [entitlementsData]);
    var fetchCandidateInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getCandidateProfileByIdURL, response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    getCandidateProfileByIdURL = TANDYM_CONSTANTS.API_URLS.CANDIDATE_PROFILE_GET;
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(getCandidateProfileByIdURL, {
                            additionDetails: true,
                        })];
                case 1:
                    response = _b.sent();
                    if (response) {
                        setProfileData(response.response);
                        dispatch(setNavigationGuardModalPopup({
                            navigationGuardModalPopup: false,
                        }));
                        dispatch(setTabNavigationGuard({
                            mainTabNavigationGuard: false,
                        }));
                        dispatch(setNavigationHistory({
                            navigationHistory: '',
                        }));
                        dispatch(setPII({
                            isPII: (_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.isPII,
                        }));
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _b.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var refreshProfile = function () {
        fetchCandidateInfo();
    };
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: 'Candidate',
            action: 'Click',
            label: "Candidate Profile ".concat(label, " tab"),
        });
    };
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsxs(CandidateApiContext.Provider, __assign({ value: {
            profileData: profileData !== null && profileData !== void 0 ? profileData : null,
            refreshProfile: refreshProfile,
        } }, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsxs(Box, __assign({ className: 'sub-route-section profile' }, { children: [_jsxs(Box, __assign({ sx: { mb: '20px' } }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'sub-routes-header', fontSize: fontSizeDynamic(24) }, { children: "My Profile" }))] })), _jsx(Box, __assign({ className: 'sub-tab-header-section' }, { children: _jsx(Tabs, { headerTabs: tabs, tabSize: 'small', tabClick: handleClickedTab }) })), _jsx(Outlet, { context: { fetchCandidateInfo: fetchCandidateInfo } })] }))] })));
};
export default Profile;
