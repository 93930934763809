var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TrainingBannerStyle, TrainingStyle } from './InfoCenter.styled';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Training = function () {
    var _a = useState(true), renderComponent = _a[0], setRenderComponent = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        setRenderComponent(entitlementsData.entitlementDto.candidateInfoCenterJson.complianceTraining);
    }, [entitlementsData]);
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsx(TrainingStyle, { children: _jsxs(Box, __assign({ className: 'compliance-training' }, { children: [_jsxs(Box, __assign({ className: 'banner img-1', id: 'compliance-training-banner-1' }, { children: [_jsx(Typography, __assign({ variant: 'h1', id: 'compliance-training', fontSize: fontSizeDynamic(32) }, { children: "Compliance Training" })), _jsx(Typography, __assign({ variant: 'h6', id: 'compliance-training-info', fontSize: fontSizeDynamic(18) }, { children: "Complete Your Required Compliance Training Here: Access all mandatory courses to get fully prepared and ready for your new role!" }))] })), _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { mb: '44px' } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(TrainingBannerStyle, { children: _jsxs(Box, __assign({ className: 'banner img-2', id: 'compliance-training-banner-2' }, { children: [_jsx(Typography, __assign({ id: 'harassment-training', variant: 'h2', fontSize: fontSizeDynamic(20) }, { children: "Kantola Sexual Harassment Training" })), _jsx(Typography, __assign({ id: 'harassment-training-info', variant: 'h6', fontSize: fontSizeDynamic(16) }, { children: "An E-mail will be sent by Kantola regarding the sexual harassment training." }))] })) }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(TrainingBannerStyle, { children: _jsxs(Box, __assign({ className: 'banner img-3', id: 'compliance-training-banner-3' }, { children: [_jsx(Typography, __assign({ id: 'client-training', variant: 'h2', fontSize: fontSizeDynamic(20) }, { children: "Client Training" })), _jsx(Typography, __assign({ id: 'client-training-info', variant: 'h6', fontSize: fontSizeDynamic(16) }, { children: "Client-specific compliance training may be sent out by Tandym\u2019s Onboarding Coordinator" }))] })) }) }))] }))] })) }));
};
export default Training;
