var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState } from 'react';
import { useAPI } from '../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../shared/Constants';
export var useDownloadResume = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var httpGet = useAPI().httpGet;
    var downloadResume = function () { return __awaiter(void 0, void 0, Promise, function () {
        var response, error_1, errorMessage_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    setErrorMessage('');
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_RESUME_DOWNLOAD_GET)];
                case 1:
                    response = _a.sent();
                    if (response.fileContents) {
                        downloadFile(response.fileContents, response.contentType, response.fileDownloadName);
                    }
                    else if (response.error) {
                        throw new Error(response.message || 'An error occurred while downloading the resume.');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    errorMessage_1 = error_1 instanceof Error ? error_1.message : 'Unexpected error occurred.';
                    console.error('Error downloading resume:', errorMessage_1);
                    setErrorMessage(errorMessage_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var downloadFile = function (fileContents, contentType, fileName) {
        var byteCharacters = atob(fileContents);
        var byteNumbers = new Uint8Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var blob = new Blob([byteNumbers], { type: contentType });
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        // Clean up resources after the download
        setTimeout(function () {
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        }, 100);
    };
    return { downloadResume: downloadResume, isLoading: isLoading, errorMessage: errorMessage };
};
export var useFileUpload = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(''), successMessage = _c[0], setSuccessMessage = _c[1];
    var httpPost = useAPI().httpPost;
    var fileChange = function (event) { return __awaiter(void 0, void 0, Promise, function () {
        var file, formData, response, error_2, errorMessage_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
                    if (!file) {
                        setErrorMessage('No file selected.');
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    setIsLoading(true);
                    setErrorMessage('');
                    setSuccessMessage('');
                    formData = new FormData();
                    formData.append('resumeFile', file);
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_RESUME_UPLOAD_POST, formData, undefined, true, true)];
                case 2:
                    response = _b.sent();
                    // Handle the response
                    if (!response.error) {
                        setSuccessMessage(response.message || 'File uploaded successfully.');
                    }
                    else {
                        setErrorMessage(response.message || 'An error occurred while uploading the file.');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    errorMessage_2 = error_2 instanceof Error ? error_2.message : 'Unexpected error occurred.';
                    console.error('Error uploading file:', errorMessage_2);
                    setErrorMessage(errorMessage_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return {
        fileChange: fileChange,
        isLoading: isLoading,
        errorMessage: errorMessage,
        successMessage: successMessage,
    };
};
