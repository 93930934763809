var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { Input, InputAdornment, styled } from '@mui/material';
import { fontSizeDynamic } from '../components/fontsizeutils/fontSizeUtils';
var CustomMaskedInput = forwardRef(function CustomMaskedInput(props, ref) {
    var onChange = props.onChange, value = props.value, id = props.id, testId = props.testId, other = __rest(props, ["onChange", "value", "id", "testId"]);
    var handleAccept = function (newValue) {
        var numericValue = newValue.replace(/[^0-9]/g, '');
        if (numericValue !== value) {
            onChange({
                target: { name: props.name, value: numericValue },
            });
        }
    };
    return (_jsx(IMaskInput, __assign({ id: id, "data-testid": testId }, other, { inputRef: ref, value: value, onAccept: handleAccept })));
});
var CustomInputAdornment = styled(InputAdornment)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .MuiTypography-root': {
            fontSize: fontSizeDynamic(16),
        },
    });
});
var MobileNumberMask = function (_a) {
    var id = _a.id, testId = _a.testId, placeholder = _a.placeholder, value = _a.value, onChange = _a.onChange, name = _a.name, countryCode = _a.countryCode;
    return (_jsx(Input, { id: id, "data-testid": testId, fullWidth: true, style: { fontSize: fontSizeDynamic(16) }, inputComponent: CustomMaskedInput, startAdornment: _jsx(CustomInputAdornment, __assign({ position: 'start' }, { children: countryCode })), inputProps: {
            mask: '(000) 000-0000',
            placeholder: placeholder,
            lazy: true,
            definitions: {
                '0': /[0-9]/,
            },
            value: value,
            onChange: onChange,
            name: name,
            id: id,
            testId: testId, // Pass testId to inputProps
        } }));
};
export default MobileNumberMask;
