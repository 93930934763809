import { jsx as _jsx } from "react/jsx-runtime";
import FaqQuestion from './FaqQuestion';
import { useLocation } from 'react-router-dom';
import { Throttle } from '../../utilities/Throttle';
import { useEffect } from 'react';
import { Box } from '@mui/material';
var FAQComponent = function (props) {
    var faqs = props.faqs;
    var location = useLocation();
    useEffect(function () {
        var handlePopState = Throttle(function (event) {
            event.preventDefault();
            if (location.pathname === '/faqs/sick-time') {
                window.history.go(-2);
            }
        }, 1000);
        window.addEventListener('popstate', handlePopState);
        return function () {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location]);
    return (_jsx(Box, { children: _jsx(FaqQuestion, { faqs: faqs }) }));
};
export default FAQComponent;
